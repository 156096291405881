import CoreApi from './api/CoreApi';
import TagmanagerApi from './api/TagmanagerApi';

if (process.env.REACT_APP_SPORTNET_API_BASE_URL) {
  CoreApi.setBaseUrl(String(process.env.REACT_APP_SPORTNET_API_BASE_URL));
} else {
  throw Error('REACT_APP_SPORTNET_API_BASE_URL not set');
}

if (process.env.REACT_APP_API_BASE_URL) {
  TagmanagerApi.setBaseUrl(String(process.env.REACT_APP_API_BASE_URL));
} else {
  throw Error('REACT_APP_API_BASE_URL not set');
}
