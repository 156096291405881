import { ApiError, getApiError } from '../../../library/api';
import {
  SmartTagKeyValuePair,
  SmarttagDetail,
  SmarttagDetailUpdatePayload,
} from '../../../library/Common';
import React from 'react';
import TagmanagerApi from '../../../api/TagmanagerApi';

const initialState: {
  isLoading: boolean;
  isError: boolean;
  error?: ApiError;
  smarttagDetail?: SmarttagDetail;
} = {
  isLoading: false,
  isError: false,
};

type State = typeof initialState;

type Action = {
  type: string;
  payload?: {
    isLoading?: boolean;
    error?: ApiError;
    smarttagDetail?: SmarttagDetail;
    updatedData?: SmarttagDetailUpdatePayload;
  };
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'init': {
      return {
        ...initialState,
      };
    }
    case 'start': {
      return {
        ...initialState,
        isLoading: true,
      };
    }
    case 'done': {
      return {
        ...initialState,
        smarttagDetail: action.payload?.smarttagDetail,
      };
    }
    case 'error': {
      return {
        ...initialState,
        error: action.payload?.error,
        isError: true,
      };
    }
    default: {
      return state;
    }
  }
};

const useSmarttagDetail = (
  appSpace: string,
  { key, value }: SmartTagKeyValuePair
) => {
  const [state, dispatch] = React.useReducer(reducer, { ...initialState });

  React.useEffect(() => {
    (async () => {
      try {
        dispatch({
          type: 'start',
        });
        const smarttagDetail = (await TagmanagerApi.publicGetSmarttag(
          appSpace,
          key,
          value
        )) as SmarttagDetail;
        dispatch({
          type: 'done',
          payload: {
            smarttagDetail,
          },
        });
      } catch (e) {
        const error = getApiError(e);
        dispatch({
          type: 'error',
          payload: {
            error,
          },
        });
      }
    })();
  }, [appSpace, key, value]);

  return { ...state };
};

export default useSmarttagDetail;
