import { SmartTagKeyValuePair, SmartTagSelection } from '../../library/Common';
import { rem } from 'polished';
import { useAuth } from '@sportnet/auth-react/AuthProvider';
import { useLocation, useNavigate } from 'react-router';
import CustomContextBar from './CustomContextBar';
import DataGrid from './DataGrid';
import DefaultFilter from '@sportnet/ui/Filter';
import Loader from '@sportnet/ui/Loader';
import LoaderOverflow from '../../components/LoaderOverflow';
import NotFound from '@sportnet/ui/NotFound';
import React from 'react';
import ScrollLayout from '@sportnet/ui/Layouts/ScrollLayout';
import SmarttagDetail from './SmarttagDetail';
import SmarttagKeyPrompt from './SmarttagKeyPrompt';
import SmarttagPrompt from './SmarttagPrompt';
import __ from '../../utilities/getText';
import countSmarttagsInSelection from '../../utilities/countSmarttagsInSelection';
import smarttagsSelectionToStandardFormat from '../../utilities/smarttagsSelectionToStandardFormat';
import styled from 'styled-components';
import useAllSmarttags from './hooks/useAllSmarttags';
import useQuery, { StringParam } from '@sportnet/query-hoc/useQuery';
import useSmarttagsMutations from './hooks/useSmarttagsMutations';
import withPopups, { WithPopupsProps } from '../../components/WithPopups';

const Wrapper = styled.div`
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  background-color: white;
  max-width: 100%;
`;

const NotFoundWrapper = styled.div`
  width: 100%;
  padding: ${rem(32)} 0;
`;

const SmartTagsRowWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 40px;
  padding-bottom: 56px;
  background-color: white;
  max-width: 100%;
  overflow-x: auto;
`;

const LoaderAllTagsWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-top: -40px;
`;

const QUERY_HOC_CONFIG = {
  parameters: {
    q: StringParam(''),
  },
};

const Overview: React.FC<WithPopupsProps> = ({ alert, confirm }) => {
  const { ppo: appSpace = '' } = useAuth();

  const [editOpened, setEditOpened] = React.useState(false);
  const [addNewOpened, setAddNewOpened] = React.useState(false);
  const [addNewPredefinedKey, setAddNewPredefinedKey] = React.useState('');
  const [selectedSmarttag, setSelectedSmarttag] =
    React.useState<SmartTagKeyValuePair | null>(null);
  const [renameOpened, setRenameOpened] = React.useState(false);
  const [moveOpened, setMoveOpened] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const [smarttagSelection, setSmartTagSelection] =
    React.useState<SmartTagSelection>({});

  const { query, setQuery } = useQuery(
    location.search,
    (serializedQuery) =>
      navigate({
        search: serializedQuery,
      }),
    QUERY_HOC_CONFIG
  );

  const {
    smarttags,
    isLoading: isAllSmarttagsLoading,
    isError: isAllSmarttagsError,
    refetch,
    updateSmarttagsInStore,
    setSystemFlagInStore,
  } = useAllSmarttags({ appSpace });

  const {
    isLoading: isSmarttagMutationLoading,
    moveSmarttags,
    createNewSmarttag,
    renameSmarttags,
    deleteSmarttags,
    setSystemKey,
    unsetSystemKey,
  } = useSmarttagsMutations({
    appSpace,
  });

  const smarttagsSelectionCount = React.useMemo(
    () => countSmarttagsInSelection(smarttagSelection),
    [smarttagSelection]
  );

  const selectionInSmarttagsFormat = React.useMemo(
    () => smarttagsSelectionToStandardFormat(smarttagSelection),
    [smarttagSelection]
  );

  const handleOnClickAddNew = () => {
    setAddNewOpened(true);
    setAddNewPredefinedKey('');
  };

  const openAddNewWithPredefinedKey = (val: { key: string }) => {
    setAddNewOpened(true);
    setAddNewPredefinedKey(val.key);
  };

  const closeAddNew = () => {
    setAddNewOpened(false);
  };

  const handleOnClickRename = () => {
    setRenameOpened(true);
  };

  const closeRename = () => {
    setRenameOpened(false);
  };

  const handleOnClickMove = () => {
    setMoveOpened(true);
  };

  const closeMove = () => {
    setMoveOpened(false);
  };

  const handleQueryFilterChange = (e: string) => {
    setQuery({
      q: e,
    });
  };

  const handleOnSelect = (smarttags: SmartTagKeyValuePair[]) => {
    setSmartTagSelection((prev) => {
      const result = smarttags.reduce(
        (acc: SmartTagSelection, smarttag) => {
          if (smarttag.key in acc) {
            acc[smarttag.key][smarttag.value] = true;
          } else {
            acc[smarttag.key] = {
              [smarttag.value]: true,
            };
          }
          return acc;
        },
        { ...prev }
      );
      return result;
    });
  };

  const handleOnDelesect = (smarttags: SmartTagKeyValuePair[]) => {
    setSmartTagSelection((prev) => {
      return smarttags.reduce(
        (acc: SmartTagSelection, smarttag) => {
          if (smarttag.key in acc) {
            delete acc[smarttag.key][smarttag.value];
          }
          return acc;
        },
        { ...prev }
      );
    });
  };

  const handleOnDeselectAllFromColumn = (key: string) => {
    setSmartTagSelection((prev) => {
      const cp = { ...prev };
      delete cp[key];
      return cp;
    });
  };

  const handleRemoveAllFromSelection = () => {
    setSmartTagSelection({});
  };

  /**
   * Vytvorenie noveho tagu
   * @param newSmarttag
   */
  const handleCreateNew = async (newSmarttag: SmartTagKeyValuePair) => {
    createNewSmarttag({
      newSmarttag,
      onSettled: () => {
        closeAddNew();
      },
      onSuccess: (response) => {
        updateSmarttagsInStore(response.tags ?? []);
      },
      onError: () => {
        alert('Nepodarilo sa vytvoriť tag');
      },
    });
  };

  /**
   * Presunutie tagu
   * @param param0
   */
  const handleMove = async ({ key: newKey }: { key: string }) => {
    moveSmarttags({
      newKey,
      smarttagsToMove: smarttagSelection,
      onSettled: () => {
        closeMove();
      },
      onSuccess: (response) => {
        updateSmarttagsInStore(response.tags ?? []);
        setSmartTagSelection({});
      },
      onError: () => {
        alert('Nepodarilo sa presunúť tagy');
      },
    });
  };

  /**
   * premenovanie
   * @param newSmarttag
   */
  const handleRename = async (newSmarttag: { key: string; value: string }) => {
    renameSmarttags({
      newSmarttag,
      smarttagsToRename: smarttagSelection,
      onSettled: () => {
        closeRename();
      },
      onSuccess: (response) => {
        updateSmarttagsInStore(response.tags ?? []);
        setSmartTagSelection({});
      },
      onError: () => {
        alert('Nepodarilo sa premenovať tag!');
      },
    });
  };

  /**
   * vymazanie
   */
  const handleOnClickDelete = async () => {
    const confirmed = await confirm(
      __(`Naozaj odstrániť smarttagy (${smarttagsSelectionCount})?`)
    );
    if (confirmed) {
      deleteSmarttags({
        smarttagsToDelete: smarttagSelection,
        onSuccess: (response) => {
          updateSmarttagsInStore(response.tags ?? []);
          setSmartTagSelection({});
        },
        onError: () => {
          alert('Nepodarilo sa zmazať tagy!');
        },
      });
    }
  };

  /**
   * Nastavit systemovy tag
   * @param smarttagKey
   */
  const handleClickSetSystemTag = (smarttagKey: string) => {
    setSystemKey({
      smarttagKey,
      onSuccess: () => {
        setSystemFlagInStore(smarttagKey, true);
      },
      onError: () => {
        alert('Nepodarilo sa nastavit systemovy tag!');
      },
    });
  };

  const handleClickUnsetSystemTag = (smarttagKey: string) => {
    unsetSystemKey({
      smarttagKey,
      onSuccess: () => {
        setSystemFlagInStore(smarttagKey, false);
      },
      onError: () => {
        alert('Nepodarilo sa nastavit zrusenie systemoveho tagu!');
      },
    });
  };

  const handleEditClick = async (smarttag: SmartTagKeyValuePair) => {
    setEditOpened(true);
    setSelectedSmarttag(smarttag);
  };
  return (
    <ScrollLayout
      topFixed={
        !isAllSmarttagsLoading &&
        smarttags !== undefined && (
          <DefaultFilter
            value={{
              q: query.q ? `${query.q}` : '',
            }}
            onChange={(nextFilterValue) => {
              handleQueryFilterChange(
                nextFilterValue.q ? (nextFilterValue.q as string) : ''
              );
            }}
            filters={[
              {
                type: 'query',
                name: 'q',
                placeholder: __('Zadajte názov smart:tagu'),
              },
            ]}
          />
        )
      }
      bottomFixed={
        <CustomContextBar
          selectionCount={smarttagsSelectionCount}
          onClickAddNew={handleOnClickAddNew}
          onClickDelete={handleOnClickDelete}
          onClickMove={handleOnClickMove}
          onClickRename={handleOnClickRename}
          onRemoveAllFromSelection={handleRemoveAllFromSelection}
        />
      }
    >
      <Wrapper>
        {isAllSmarttagsLoading || smarttags === undefined ? (
          <LoaderAllTagsWrapper>
            <Loader size="xl" />
          </LoaderAllTagsWrapper>
        ) : isAllSmarttagsError ? (
          <NotFoundWrapper>
            <NotFound
              title={__('Ups! Nastala chyba pri načítaní')}
              icon="error"
              actionLabel={__('Skúsiť znova')}
              onClickAction={refetch}
            />
          </NotFoundWrapper>
        ) : smarttags.length === 0 ? (
          <NotFoundWrapper>
            <NotFound title={__('Žiadne smarttagy')} icon="file" />
          </NotFoundWrapper>
        ) : (
          <>
            <SmartTagsRowWrapper>
              <DataGrid
                smarttags={smarttags ?? []}
                query={query.q}
                onClickAdd={openAddNewWithPredefinedKey}
                onClickEdit={handleEditClick}
                onSelect={handleOnSelect}
                onDeselect={handleOnDelesect}
                onDeselectAllFromColumn={handleOnDeselectAllFromColumn}
                smarttagSelection={smarttagSelection}
                onClickSetSystemTag={handleClickSetSystemTag}
                onClickUnsetSystemTag={handleClickUnsetSystemTag}
              />
              {isSmarttagMutationLoading && <LoaderOverflow />}
            </SmartTagsRowWrapper>
          </>
        )}
        <SmarttagPrompt
          isOpen={addNewOpened}
          title={__('Pridať nový')}
          suggestedSmarttags={
            addNewPredefinedKey
              ? [{ key: addNewPredefinedKey, values: [] }]
              : undefined
          }
          onSubmit={handleCreateNew}
          onCancel={closeAddNew}
        />
        <SmarttagPrompt
          isOpen={renameOpened}
          title={smarttagsSelectionCount > 1 ? __('Zlúčiť') : __('Premenovať')}
          suggestedSmarttags={selectionInSmarttagsFormat}
          onSubmit={handleRename}
          onCancel={closeRename}
        />
        {smarttags !== undefined && (
          <SmarttagKeyPrompt
            isOpen={moveOpened}
            title={__('Presunúť vybrané do')}
            availableKeys={
              Object.keys(smarttagSelection).length === 1
                ? smarttags
                    .filter(
                      (stag) => stag.key !== Object.keys(smarttagSelection)[0]
                    )
                    .map((stag) => stag.key)
                : smarttags.map((stag) => stag.key)
            }
            onSubmit={handleMove}
            onCancel={closeMove}
          />
        )}
      </Wrapper>
      {selectedSmarttag && (
        <SmarttagDetail
          appSpace={appSpace}
          isOpen={editOpened}
          smarttag={selectedSmarttag}
          onClose={() => {
            setSelectedSmarttag(null);
          }}
        />
      )}
    </ScrollLayout>
  );
};

export default withPopups(Overview);
