import { FORM_ERROR } from 'final-form';
import { Field, Form } from 'react-final-form';
import { IContent } from '@sportnet/content/utilities/library';
import { ModalActions } from '../../../components/Modal/modal';
import { SmartTagKeyValuePair } from '../../../library/Common';
import { ThemeProvider } from 'styled-components';
import { theme as defaultContentTheme } from '@sportnet/content/theme/theme';
import { getApiError } from '../../../library/api';
import { rem } from 'polished';
import { styled } from 'styled-components';
import Button from '@sportnet/ui/Button';
import FormField from '@sportnet/ui/FormField';
import Header from '@sportnet/ui/Header';
import Modal, { ModalContent } from '@sportnet/ui/Modal';
import React from 'react';
import SmartTagEditorPromptWidgets from '../SmartTagEditPromptWidgets';
import TagmanagerApi from '../../../api/TagmanagerApi';
import __ from '../../../utilities/getText';
import sleep from '../../../utilities/sleep';
import useSmarttagDetail from '../hooks/useSmarttagDetail';
import withPopups, { WithPopupsProps } from '../../../components/WithPopups';

const ContentWrapper = styled.div`
  min-height: ${rem(400)};
  position: relative;
`;

interface OwnProps {
  appSpace: string;
  smarttag: SmartTagKeyValuePair;
  isOpen: boolean;
  onClose: () => void;
}

interface SmartTagDetailPayload {
  meta?: {
    title?: string;
    description?: string;
  };
  url?: string;
  content?: IContent;
}

const SmarttagDetail: React.FC<OwnProps & WithPopupsProps> = ({
  alert,
  appSpace,
  smarttag,
  isOpen,
  onClose,
}) => {
  const { isLoading, smarttagDetail } = useSmarttagDetail(appSpace, smarttag);

  const handleSubmit = async (values: SmartTagDetailPayload) => {
    // toto je len na efekt :)
    await sleep(300);

    // toto premapovanie riesi problem s `null` hodnotami v `meta` resp `url`
    // ak sme nacitali tag, ktory tieto fieldy neobsahuje
    const payload: SmartTagDetailPayload = {
      meta: {
        title: '',
        description: '',
        ...(values.meta || {}),
      },
      url: values.url || '',
      content: values.content || [],
    };
    try {
      await TagmanagerApi.adminSetSmarttagDetail(
        appSpace,
        smarttag.key,
        smarttag.value,
        {},
        payload
      );
      onClose();
    } catch (e) {
      const error = getApiError(e);
      const errorMessage = `Uloženie smarttagu sa nepodarilo! (${
        error.details.statusCode
      } ${error.details.name ?? error.message}) `;
      await alert(errorMessage);
      return {
        [FORM_ERROR]: errorMessage,
      };
    }
    return {};
  };

  const initialData: SmartTagDetailPayload = React.useMemo(() => {
    return {
      meta: smarttagDetail?.meta ?? { title: '', description: '' },
      url: smarttagDetail?.url ?? '',
      content: smarttagDetail?.content ?? [],
    };
  }, [smarttagDetail]);

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <Form
        initialValues={initialData}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, dirty }) => {
          return (
            <form
              onSubmit={(e) => {
                if (dirty) {
                  handleSubmit(e);
                } else {
                  e.preventDefault();
                }
              }}
            >
              <ModalContent>
                <ContentWrapper>
                  <Header size="m" withSeparator>
                    {__('Upraviť smarttag')} "{smarttag.key}:{smarttag.value}"
                  </Header>
                  <FormField
                    value={smarttagDetail?.identifier ?? ''}
                    label={__('Identifikátor')}
                    readOnly
                    disabled
                  />
                  <Field name="meta.title">
                    {({ input: { name, value, onChange }, meta }) => (
                      <FormField
                        name={name}
                        value={value}
                        label={__('Meta title')}
                        counter={{ characters: true }}
                        onChange={onChange}
                        error={meta.error && meta.touched ? meta.error : ''}
                        disabled={isLoading}
                      />
                    )}
                  </Field>
                  <Field name="meta.description">
                    {({ input: { name, value, onChange }, meta }) => (
                      <FormField
                        type="textarea"
                        rows={4}
                        name={name}
                        value={value}
                        label={__('Meta description')}
                        counter={{ characters: true }}
                        onChange={onChange}
                        error={meta.error && meta.touched ? meta.error : ''}
                        disabled={isLoading}
                      />
                    )}
                  </Field>
                  <Field name="url">
                    {({ input: { name, value, onChange }, meta }) => (
                      <FormField
                        name={name}
                        value={value}
                        label={__('URL')}
                        onChange={onChange}
                        error={meta.error && meta.touched ? meta.error : ''}
                        disabled={isLoading}
                        info={__(
                          'Kliknutím na smarttag bude používateľ presmerovaný na túto URL adresu.'
                        )}
                        placeholder={'https://...'}
                      />
                    )}
                  </Field>
                  <Field name="content">
                    {({ input: { onChange } }) => (
                      <ThemeProvider theme={defaultContentTheme}>
                        {!!smarttagDetail && (
                          <SmartTagEditorPromptWidgets
                            initialValue={smarttagDetail?.content || []}
                            onChange={onChange}
                          />
                        )}
                      </ThemeProvider>
                    )}
                  </Field>
                </ContentWrapper>
              </ModalContent>
              <ModalActions>
                <Button onClick={onClose}>{__('Zavrieť')}</Button>
                <Button
                  primary
                  type="submit"
                  disabled={submitting || !dirty}
                  loading={submitting}
                >
                  {__('Uložiť')}
                </Button>
              </ModalActions>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default withPopups(SmarttagDetail);
